import React, {
  createContext, useContext, useEffect, useReducer,
} from 'react';
import { logError } from 'utils/errorLogger';

const defaultInitialCartState = {
  items: [],
  totalValue: 0,
};

/**
 * cartTotal
 *
 * @param items
 *
 * calculate declared value of all cart items.
 * Ignore items that have a valid sears care plan.
 *
 */
const cartTotal = (items) => {
  const calculatedDeclaredTotal = items.reduce((acc, obj) => acc + (parseFloat(obj.declaredValueUsd) || 0), 0);
  const declaredTotal = (!calculatedDeclaredTotal || calculatedDeclaredTotal < 97) ? 97 : calculatedDeclaredTotal;
  return declaredTotal;
};

const cartReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case 'clear': {
      const modifyCart = { ...state };
      modifyCart.totalValue = 0;
      modifyCart.items = [];
      return { ...modifyCart };
    }
    case 'removeItem': {
      const modifyCart = { ...state };
      const items = [...modifyCart.items];
      items.splice(action.payload, 1);
      modifyCart.totalValue = cartTotal(items);
      modifyCart.items = items;
      return { ...modifyCart };
    }
    case 'addItem': {
      const { id } = action.payload;
      const updatedCart = { ...state };
      const item = { ...action.payload.value, declaredValueUsd: parseFloat(action.payload.value.declaredValueUsd) || 0 };
      if (id === null) {
        // item doesn't exist push to array
        updatedCart.items.push(item);
      } else {
        // item does exist update current item
        updatedCart.items[id] = item;
      }
      // update total
      updatedCart.totalValue = cartTotal(updatedCart.items);
      return { ...updatedCart };
    }
    default:
      logError(new Error(JSON.stringify(state)), 'CartProvider called action that does not exist');
      throw new Error(JSON.stringify(state));
  }
};

const CartGetterContext = createContext();
const CartDispatcherContext = createContext();

export const CartProvider = ({ children }) => {
  let initialValueOfCart = defaultInitialCartState;
  if (typeof window !== 'undefined') {
    initialValueOfCart = JSON.parse(window?.localStorage.getItem('CartContext')) || initialValueOfCart;
  }
  const [state, dispatch] = useReducer(cartReducer, { ...initialValueOfCart });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.localStorage.setItem('CartContext', JSON.stringify(state));
    }
  }, [state]);

  return (
    <CartDispatcherContext.Provider value={dispatch}>
      <CartGetterContext.Provider value={state}>
        {children}
      </CartGetterContext.Provider>
    </CartDispatcherContext.Provider>
  );
};

export const useCartGetter = () => {
  const context = useContext(CartGetterContext);
  if (context === undefined) {
    throw new Error('useCartGetter must be used within a CartProvider.');
  }
  return context;
};

export const useCartDispatcher = () => {
  const context = useContext(CartDispatcherContext);
  if (context === undefined) {
    throw new Error('useCartDispatcher must be used within a CartProvider');
  }
  return context;
};
